import './App.css';
import '../src/css/scrollbar.css';
import { useEffect, useState } from 'react';
import MainRoutes from './pages/MainRoutes';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import { Link } from 'react-router-dom';
import { BottomNavBar } from './components/BottomNavBar';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Link as LinkScroll } from "react-scroll";
import { Snackbar } from '@mui/material';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
function App() {
  // setting up dark and light theme
  const [theme, setTheme] = useState('dark-theme')
  const [navBarTheme, setNavBarTheme] = useState('dark-nav-bar')

  const [snackBarState, setstate] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'centre'
  });

  const { vertical, horizontal, open } = snackBarState;
  const socialMediaList = [
    { icon: <FaFacebook />, link: 'https://www.facebook.com/bib4sh', animationDuration: '1000' },
    { icon: <FaInstagram />, link: 'https://www.instagram.com/bib4sh', animationDuration: '1500' },
    { icon: <FaLinkedin />, link: 'https://www.linkedin.com/in/bibash-kattel-a23186162', animationDuration: '2000' },
  ];

  const openSnackBar = (newState) => {
    setstate({ ...newState, open: true })
  }

  const closeSnackBar = () => {
    setstate({ ...snackBarState, open: false })
  }

  const firebaseConfig = {
    apiKey: "AIzaSyBVkGT09bqqwF7_hPdrhLzZjdyqJpJlXlA",
    authDomain: "bibash-portfolio-react.firebaseapp.com",
    projectId: "bibash-portfolio-react",
    storageBucket: "bibash-portfolio-react.appspot.com",
    messagingSenderId: "517294058884",
    appId: "1:517294058884:web:e6be46257d5116587d81d1",
    measurementId: "G-BFLQD1RFC5"
  };
  
  // Initialize Firebase



  useEffect(() => {
    document.body.className = theme;
    document.body.className = navBarTheme;
    setTimeout(openSnackBar({ vertical: 'top', horizontal: 'right' }), 3000);
    setTimeout(closeSnackBar, 9000);
    
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    

    console.log(window.location.href)

  }, [])


  return (
    <div className={`App ${theme}`}>

      <Snackbar
        // style={{ fontFamily: '"Comic Sans"', fontWeight:'lighter' }}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={closeSnackBar}
        message="Please browse through PC for better experience !"
        key={vertical + horizontal}
        data-aos="fade-left" data-aos-duration="1500"
      />

      {/* Social media container */}
      <div className='sticky-socialmedia-container'>
        {socialMediaList.map((data, index) => {
          return (
            <Link
              key={index}
              className="social-medias"
              data-aos="fade-down"
              data-aos-duration={data.animationDuration}
              target='_blank' to={data.link}>
              {data.icon}
            </Link>
          );
        })}

      
      </div>

      <div className='sticky-scroll-arrow'>

        <LinkScroll
          activeClass="active"
          to="endofpage"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <button>
            <KeyboardDoubleArrowDownIcon sx={{ fontSize: 40 }} />
          </button>

        </LinkScroll>

      </div>

      {/* Main body contents */}
      <div className='main-body'>
        {/* Main routes or pages to be displayed here */}
        <div className="mainbody-wrapper">
          {"<html>"} <br />
          &nbsp; &nbsp; {"<body>"}
        </div>

        {/* Display main routes */}
        <MainRoutes />

        <div className="mainbody-wrapper" id="endofpage">
          &nbsp; &nbsp;  {"</body>"} <br />
          {"</html>"}
        </div>

        {/* End of main routes  */}

        {/* Nav bar  */}
        {/* <button onClick={toogleTheme}> change theme </button> */}
      </div>
      {/*bottom  Nav bar */}
      <div className={`nav-bar ${navBarTheme}`}>
        <BottomNavBar />
      </div>

      
      <TawkMessengerReact
              propertyId="64b7ba5594cf5d49dc648b4d"
              widgetId="1h5mqvnsj"
            />

      {/* End of nav bar */}
    </div>
  );
}

export default App;
